<template>
  <div>
    <el-breadcrumb style="margin:0 0 10px 10px;" separator="/">
        <el-breadcrumb-item>常规配置</el-breadcrumb-item>
        <el-breadcrumb-item>个人信息</el-breadcrumb-item>
      </el-breadcrumb>
    <div class="content">
      <div class="tt">个人信息</div>
      <div class="xian"></div>
      <el-form v-if="show" :model="form" :rules="rules" ref="enterForm" label-width="140px" style="width: 100%">
            <el-form-item label="账号：">
              <span>{{form.username}}</span>
            </el-form-item>
            <el-form-item label="真实姓名：">
              <span>{{form.real_name}}</span>
            </el-form-item>

            <!-- <el-form-item label="类型：">
              <span>{{form.duty == 1 ? '超级管理员' : '管理员'}}</span>
            </el-form-item> -->
            <el-form-item label="备注：" v-if="form.remark">
              <span>{{form.remark}}</span>
            </el-form-item>
            <el-form-item label="状态：">
              <span>{{form.status == 1 ? '正常' : '冻结'}}</span>
            </el-form-item>
      </el-form>
      <el-form v-else :model="form" :rules="rules" ref="enterForm" label-width="140px" style="width: 100%">
            <el-form-item label="账号：" prop="username">
              <el-input class="int" v-model="form.username" clearable></el-input>
              <span style="color:#ccc;margin-left:5px;">注：字母数字下划线(2-10位)</span>
            </el-form-item>
            
            <el-form-item label="真实姓名：" prop="real_name">
              <el-input class="int" v-model="form.real_name" clearable></el-input>
            </el-form-item>
            <el-form-item label="密码：">
              <el-input class="int" v-model="form.password" clearable show-password></el-input>
              <span style="color:#ccc;margin-left:5px;">注：不填默认为原密码</span>
            </el-form-item>
            <!-- <el-form-item label="类型：">
              <el-radio-group v-model="form.duty">
                  <el-radio :label="1">超级管理员</el-radio>
                  <el-radio :label="2">管理员</el-radio>
              </el-radio-group>
            </el-form-item> -->
            <el-form-item label="备注：">
              <el-input type="textarea" class="int" :autosize="{ minRows: 3}" v-model="form.remark" clearable></el-input>
            </el-form-item>
            <!-- <el-form-item label="状态：">
              <el-radio-group v-model="form.status">
                  <el-radio :label="1">正常</el-radio>
                  <el-radio :label="2">冻结</el-radio>
              </el-radio-group>
            </el-form-item> -->
      </el-form>
      <div class="btn_box" v-if="show">
        <el-button size="small" type="cha" class="add_btn" @click="bianji">编辑</el-button>
      </div>
      <div class="btn_box" v-else>
        <el-button size="small" type="cha" class="add_btn" @click="baocun">保存</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        username: '',
        real_name: '',
        password: '',
        duty: 1,
        remark: '',
        status: 1,
        id: '',
      },
      rules: {
        username: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        real_name: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' },
        ]
      },
      show: true,
    }
  },
  created() {
    this.info()
  },
  methods: {
    //  获取用户信息
    async info() {
      const { data: res } = await this.$http.get('api/user/info')
      console.log(res)
      this.form = {
        username: res.data.username,
        real_name: res.data.real_name,
        password: '',
        duty: res.data.duty,
        remark: res.data.remark,
        status: res.data.status,
        id: res.data.id,
      }
    },
    bianji() {
      this.show = false
      this.form.password = null
    },
    // 保存
    baocun() {
      this.$refs.enterForm.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整数据')
        const { data: res } = await this.$http.post('api/user/edit', this.form)
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.show = true
        this.info()
      })
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background-color: #fff;
  padding: 13px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .tt {
    font-size: 20px;
  }
  .xian {
    width: 100%;
    height: 2px;
    background-color: #1ca6c8;
    margin: 13px auto 20px;
  }

  .int {
    width: 400px;
  }

  .add_btn {
    // width: 100px;
    margin: 60px 0 100px 140px;
  }
}
</style>

